/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $font-base;
    line-height: grid(1);
    font-weight: 400;
    color: $text-color;
    background-color: $background-color;
    background-image: $page-background;
    background-attachment: fixed;
    -webkit-text-size-adjust: 100%;
}

/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: lighten($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
p, blockquote, pre, ul, ol, dl, figure {
    margin-bottom: grid(1);
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-family: $base-font-family;
}

h1 {
  font-size: 2.5em;
  line-height: grid(2);
  margin-bottom: grid(1);
}

h2 {
  font-size: 2em;
  line-height: grid(2);
  margin-bottom: grid(1);
  border-bottom: 1px solid $low-contrast-color;
}

h3 {
  font-size: 1.5em;
  line-height: grid(2);
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: .9em;
}

// heading after p

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: grid(1);
}

blockquote {
  padding: grid(1) grid(2);
  border-left: 5px solid $brand-color;
  float: left;
  background: darken($background-color, 2%);
  p{
    color: lighten($text-color, 20%);
  }
  p:last-child {
    margin-bottom: 0;
  }

}

/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $font-small;
}

/**
 * Lists
 */
ul, ol {
//     margin-left: grid(1);
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}

/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $low-contrast-color;
        }
    }
}
