/**
 * Sidebar modules
 */

.container {
    background: darken($background-color, 10%) url('images/downfeathers.png');
}

.sidebar {
  ul {
    list-style: none;
  }
  .widget {
    table {
      background: transparent;
      border: none;
    }
  }
  .widget > div {
    margin: grid(1) auto;
  }
  h2 {
    padding: 0;
    margin: 0;
    line-height: grid(2);
  }
}

.sidebar-content {
    padding: 0 grid(1);
}

.sidebar-headshot {
    max-width: 320px;
    width: 100%;
    margin: auto;
    @include box-shadow( 3px, 3px, 4px, rgba(0,0,0,0.5))
}

.sidebar-headshot-caption {
    text-align: center;
}

.sidebar-text-box {
  @include text-background;
  padding-top: 0;
}

.sidebar-text-box-list {
    margin: 0;
  font-size: $font-base * 1.25;
  line-height: grid(1.5);
  font-weight: $semi;
}
