.mfp-title, .mfp-counter {
	font-family: $alternate-font-family;
	font-size: 1.2em;
	> a {
		// background: $brand-color;
		text-align: center;
		color: white;
		font-size: 0.8em;
		// font-family: $alternate-font-family;
		// padding: 0.2em;
		// margin-left: 2em;
	}
}

