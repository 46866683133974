// Styles for images added by various plugins

// https://github.com/bighairydave/jekyll-flickr

// img.flickr_photo {
// 	//margin: auto;
// 	padding: 1em;
// 	border: 1px solid rgb(128,128,128);
// }

a.flickr_photo {
	text-align: center;
	display: block;
}

// Image Gallery  (was for Masonry)

.flickr-gallery, .piwi-gallery {
	@extend %clearfix;
	// width: 100%;
	margin: 20px auto;
	@media (min-width: 640px) {
		// max-width: 80%
	}
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}
.gallery-img {
  // float: left;
  // max-width: 25%;
  	@media (min-width: 480px) {
		// max-width: 20%
	}
  height: auto;
  border: 2px solid white;
  flex: 1 1 max-content;
}
.flickr-set-primary {
	//width: 40%;
}
