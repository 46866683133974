/* Modules */

/**
 * Page header
 */
.site-header {
    border-top: 5px solid $brand-color;
    border-bottom: 1px solid lighten($low-contrast-color, 20%);
    min-height: 242px;
    background-color: #222629;
    background:
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.4) 70%,
          rgba(0, 0, 0, 0.6)
        ),
        url('images/panorama.jpg');

    // Needed for nav
    position: relative;
}

.header-text {
    margin: 0;
    margin: grid(1);
    color: white;
    @media (min-width: $medium) {
        margin-left: 2em;
    }
    @media (min-width: $large) {
        margin-left: 4em;
    }
}

.site-title {
    font-size: $font-large;
    line-height: grid(2);
    letter-spacing: -1px;
    margin-bottom: 0;
    text-shadow: 2px 2px 1px black;
    
    &,
    &:visited {
        color: white;
    }

    @media (min-width: $medium) {
        font-size: 2.5em;
    }
    @media (min-width: $large) {
        font-size: 3em;
    }
}

.tagline {
    max-width: 75%;
    text-shadow: 1px 1px 1px black, 0px 0px 5px rgba(0,0,0,0.5);
}

/**
 * Paginator
 */

.paginator-nav {
    @extend %clearfix;
    @media (min-width: $large) {
        max-width: 60%;
        margin-left:auto;
        margin-right: auto;
    }
    font-weight: $semi;
}

.paginator-legend {
    text-align: center;
}

.post-nav, .article-nav {
    @extend %clearfix;
}

.prevnext-newer {
    float: left;
    max-width: 49%
}
.prevnext-older {
    float: right;
    max-width: 49%
}


/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid lighten($low-contrast-color, 20%);
    //padding: $spacing-unit 0;
}
.footer-col-wrapper {
    display: flex;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $grid(1);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col {
    margin-bottom: $grid(1);
    flex: 1 0 0;
    align: center;
}

.footer-col-1 {
    // @media (min-width: $large) {
    //   @include span(4);
    // }
}

.footer-col-2 {
    // @media (min-width: $large) {
    //   @include span(4);
    // }
}

.footer-col-3 {
    // @media (min-width: $large) {
    //   @include span(4 last);
    // }
}

/**
 * Presentation of lists
 */
 
.birdlist {
    margin-top: $grid(1);
    a:visited {
    color: $brand-color;
    }
}

.birdlist-tick {}
.birdlist-year-tick {
    color: green;
    font-weight: $semi; 
    a, a:visited {
      color: green;
      font-weight: $semi;        
    }
}

.birdlist-life-tick {
    color: red;
    font-weight: $semi;
    a, a:visited {
      color: red;
      font-weight: $semi;        
    }
}

.birdlist-inline {
    @extend .birdlist;
    li {
        display: inline;
        :after {
            content: ", ";
        }
    }
    li:last-child {
        :before {
            content: "and ";
            color: $brand-color;
            font-weight: normal;
        }
        :after {
            content: ".";
            color: $brand-color;
            font-weight: normal;
        }
    }
    li:only-child {
        :before {
            content: "";
        }
    }
}
.birdlist ol {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.archive-list {
    dt {
        font-size: 1.2em;
        line-height: 2em;
    }
    dd {
        @extend .post-excerpt;
        margin-left: 2em;
        margin-bottom: 2em;
    }
}
.archive-list-post-link {
//     font-family: $base-font-family;
}

.archive-list-date {
    font-style: italic;
    color: lighten($text-color, 10%);
}

.archive-list-more {
    @extend .post-more;
}

.archive-index {
    list-style: none;
    columns: 4 12em;
}