/* Font settings */

/* Places to use something other than base font */


.site-title {
//     font-family: $base-font-family;
    font-weight: 700;
}

.tagline {
//     font-family: $alternate-font-family;
}

.menubar {
  a, a:visited {
//     font-family: $base-font-family;
  }
}

.post-link {
//   font-family: $alternate-font-family;
  font-weight: 700;
}

.post-title {
//   font-family: $alternate-font-family;
  font-weight: 700;  
}