.birdpage table {
	width: 100%;
	border-collapse: collapse;
	margin: 2em auto;
	border: 1px solid #eee;
	border-bottom: 2px solid $brand-color;

	th {
		background: $brand-color;
		color: white;
	}

	td {
		background: white;
	}

	th, td {
		border: 1px solid #eee;
		padding: 0.75em 1em;
	}
}

.birdpage a:visited {
	color: $brand-color;
}

.toggler {
	color: blue;
}

.thisone {
	color: red;
}

.toggle {
	display: none;
}
