
.specplot-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  width: 90%;
  @include tablet-landscape { // min-width 900 - breakpoint for 2-columns
    max-width: 600px;
  }
  @include desktop { // 1200 - skip big-desktop because layout is narrow on those anyway
    max-width: 720px;
  }
  overflow-x: scroll;
//   box-shadow: 2px 2px 1px 2px rgba(0,0,0,0.3);
//   position: relative;
//   min-width: 0;
}

.specplot-container > img {
  max-width: none;
  display: block;
  object-fit: none;
  object-position: left bottom;
}

.specvid {
  width:320px;
  margin: grid(1) auto;
  text-align: center;
}