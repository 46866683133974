@charset "utf-8";

// @import "susy"; // no longer using
// font is now linked in _includes/head.html rather than as below
// @import url(http://fonts.googleapis.com/css?family=Lora:400,700,400italic,700italic|Montserrat:400,700);

$base-font-family: 'Open Sans', sans-serif;
$alternate-font-family: 'Cormorant', serif;

// Variables
// $alternate-font-family: Montserrat, "Segoe UI", "Trebuchet MS", Verdana, sans-serif;
// $base-font-family: Lora, 'Goudy Old Style', Garamond, 'Big Caslon', 'Times New Roman', serif;

// $base-font-size:   16px;
// $small-font-size:  $base-font-size * 0.875;
// $base-line-height: 1.5em;
// 

// 
// $spacing-unit: 30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      rgb(0,64,0);
$low-contrast-color: #828282;

$page-background: url('images/foggy_birds.png');

/*
vertical rhythm from https://teamgaslight.com/blog/vertical-rhythm-on-the-web
to slowly replace the $spacing-unit stuff

Example usage:

selector {
  line-height: $grid(1);
}
*/

$font-size-base: 16px;
$line-height-base: 1.5;
$grid: $font-size-base * $line-height-base;
@function grid($count) {
  @return calc(#{$grid} * #{$count});
}
$font-small: $font-size-base * 0.75;
$font-base: $font-size-base;
$font-large: $font-size-base * 2;

$bold: 700;
$semi: 600; 

//breakpoints for media queries

$small: 480px;
$medium: 640px;
$large: 960px;
$oversize: 1120px;

// @import "normalize";
// 
// @import "vertical-grid";

@import "reset";

// Import partials from `sass_dir` (defaults to `_sass`)
//solarized replaces previous syntax-highlighting

@import
        "mixins",
        "base",
        "layout",
        "modules/modules",
        "modules/nav",
        "modules/post-list",
        "modules/sidebar",
        "modules/specplot",
        "birdpage",
        "vendor/solarized-light",
        "images",
        "vendor/magnific",
        "vendor/magnific_custom",
        "theme"
;
