/********* Layout is now flexbox **********/
.wrap {
  // @include container;
  max-width: 1120px;
  margin: auto;
}

.container {
    @include tablet-landscape {
        display: flex;
    }
}

.content {
  @include text-background;
  flex: 2 1 0; //This is the shorthand for flex-grow, flex-shrink and flex-basis combined. 
}
.sidebar {
  flex: 1 1 0;

          // width: min-content;
}

/******** below this line is the Jekyll default theme *************/

/**
 * Page content
 */
.page-content {
}

.page-heading {
    font-size: 20px;
}

/**
 * Posts
 */

.post-wrapper {
    //@include text-background;
}

.post-header {
//     margin-bottom: $spacing-unit;
}

.post-title {
    margin: 0;
    padding: 0;
    font-size: $font-large;
    letter-spacing: -1px;
    line-height: grid(2);
}

.post-content {
    margin-bottom: $grid(1);
}

