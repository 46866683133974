@mixin text-background {
    background: white;
    box-shadow:  inset 0 0 0.3em rgba(0,0,0,0.75);
    padding: 1em;
}

@mixin rounded($radius: 0.25em) {
  border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

%clearfix { //use @extend
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin phone {
  @media (max-width: 599px) { @content; }
}
@mixin tablet-portrait {
  @media (min-width: 600px) { @content; }
}
@mixin tablet-landscape {
  @media (min-width: 900px) { @content; }
}
@mixin desktop {
  @media (min-width: 1200px) { @content; }
}
@mixin big-desktop {
  @media (min-width: 1800px) { @content; }
}







