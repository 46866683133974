/* Styles for lists of posts eg front page, category pages */

 .post-list {// applied to a <ul> element
    margin-left: 0;
    list-style: none;
    padding: 0;
    > li {
        margin-bottom: grid(1);
        padding: 0 0 grid(1) 0;
        border-bottom: 1px solid $low-contrast-color;
        //@include text-background;
    }
    h2 {
      border-bottom: 0;
      margin-bottom: 0;
      position: relative;
      display: block;
      padding: 0px 5px;
      width: 100%;
      background: rgba(255,255,255,0.5);
      a {
          color: $brand-color;
          text-decoration: none;

          &:visited {
              color: $brand-color;
          }

          &:hover {
              color: lighten($brand-color, 15%);
              text-decoration: underline;
          }
      }
    }
    img {
      margin-top: grid(-4);
      padding-top: 4px;
    }
//     h2::before{
//       content: url('/apple-touch-icon.png');
//       height: grid(1);
//     }
}

.post-meta {
    font-size: $font-small;
    color: $low-contrast-color;
    line-height: grid(1);
      position: relative;
      display: block;
      padding: 0 5px;
      width: 100%;
      background: rgba(255,255,255,0.5);
}

.category-link {
}

.post-link {
    display: block;
    font-size: $font-base * 1.2;
    max-width: 80%;
    white-space: nowrap; 
  	overflow: hidden;
  	text-overflow: ellipsis;
    @include tablet-portrait {
      font-size: $font-large;
      line-height: grid(2);
    }
}

.post-excerpt > p {
    font-size: $font-base;
    display: inline;
}

.post-more {
    font-size: $font-small;
    font-weight: $semi;
}

li.sticky-post {
    padding: 0 grid(1) grid(1) grid(1);
    background: rgba($brand-color, 0.1);
    position: relative;
    box-shadow: -2px 2px 2px rgba(darken($brand-color, 10%), 0.2);
}

.sticky-post:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 grid(1) grid(1) 0;
  border-style: solid;
  border-color: rgba($brand-color, 0.3) #fff;
  box-shadow: -3px 3px 3px rgba(darken($brand-color, 10%), 0.5);
}

